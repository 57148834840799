import_file<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <div class="d-flex justify-content-end">
              <div class="mx-2">
                <button
                    class="btn btn-info"
                    @click="addNewPatient"
                    name="Medicine">
                  Add new patient
                </button>
              </div>
              <div class="mx-2">
                <button
                    class="btn btn-warning"
                    @click="importPatient"
                    name="Medicine">
                  <i class="fas fa-file-excel"></i> <span class="ml-3"> Import Excel</span>
                </button>
              </div>

              <div class="mx-2">
                <download-excel
                    :data="items"
                    class="btn btn-primary"
                    :fields="json_fields"
                    :worksheet="`${this.$route.name}`"
                    :name="`${this.$route.name}`">
                  <i class="fas fa-file-excel"></i> Export Excel
                </download-excel>
              </div>
              <div>
                <b-button @click="dateRangeClear" variant="danger">Clear</b-button>
              </div>
            </div>

            <!--    import modal        -->
            <b-modal
                id="importModal"
                ref="modal"
                title="Import patient "
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleImport"
            >
              <form ref="form" @submit.stop.prevent="handleSubmitImport">
                <b-form-group
                    label="Import medicine excel file"
                    label-for="name-input"
                >
                  <b-form-file
                      id="name-input"
                      ref="imported_file"
                      required
                  ></b-form-file>
                </b-form-group>
                <a :href="`${base_url}/downloadable-files/patient_import_sample_file.xlsx`" download>Download sample file</a>
              </form>
            </b-modal>


            <div>
              <div class="row justify-content-between">
                <div class="col-md-1 mt-9">
                  <div class="">
                    <b-form-group
                        class="mb-0">
                      <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>

                <div class="col-md-3 ">
                  <label for="">From</label>
                  <input type="date" v-model="start_date" class="form-control">
                </div>
                <div class="col-md-3">
                  <label for="">To</label>
                  <input type="date" v-model="end_date" class="form-control">
                </div>
                <div class="col-md-1 mt-8">
                  <button type="button" class="btn btn-info" @click="getUser">Filter</button>
                </div>
                <div class="col-md-4 mt-9">
                  <b-form @submit="searchDoctor" @reset="onReset">
                    <b-form-group
                        label-for="filter-input"
                        label-cols-sm="1"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0">
                      <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"/>

                        <b-input-group-append>
                          <b-button variant="primary" type="submit">search</b-button>
                        </b-input-group-append>

                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                        </b-input-group-append>

                      </b-input-group>

                    </b-form-group>
                  </b-form>
                </div>
              </div>
              <!-- Table -->
              <div class="row">
                <div class="col-12">
                  <b-table
                      striped hover responsive
                      :items="items.length ? filtered : items"
                      :fields="fields"
                      :current-page="currentPage"
                      :per-page="0"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      @filtered="onFiltered"
                      :filter-included-fields="filterOn"
                      thead-class="bg-primary text-white"
                      class="mt-5"
                      show-empty>


                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <p v-if="field.key == 'actions' || field.key == 'image' || field.key=='otp' || field.key=='bulk_trending'"></p>
                        <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                      </td>
                    </template>


                    <template #cell(image)="row">

                      <div class="is_online">
                        <img :src="userPhoto(row.item.image)" alt="image"
                             class="user_image" v-if="row.item.image" @click="showImage(row.item.image)"/>
                        <img src="../../../assets/img/blank.png" alt="" class="user_image" v-else>
                        <i :class="`${row.item.is_online == '1' ? 'text-green ' : 'text-danger'} fas fa-circle is_online_icon`"></i>
                      </div>
                    </template>

                    <template #cell(otp)="row">
                      <div class="is_online text-center">
                        <p v-if="row.item.otp===true"><i class="fas fa-check-circle text-success"></i></p>
                        <p v-else><i class="fas fa-times-circle text-danger"></i></p>
                      </div>
                    </template>

                    <template #cell(confirmation)="row">
                      <div class="is_online text-center">
                        <p v-if="row.item.confirmation===true"><i class="fas fa-check-circle text-success"></i></p>
                        <p v-else><i class="fas fa-times-circle text-danger"></i></p>
                      </div>
                    </template>

                    <template #cell(registration_date)="row">
                      <p>{{ row.item.registration_date }}</p>
                    </template>

                    <template #cell(email)="row">
                      <span> {{ row.item.email ? row.item.email : 'n/a' }}</span>
                    </template>




                    <template #cell(name)="row">
                      <div  v-if="row.item.parent_id">
                        <user-parent-info :parent_id="row.item.parent_id" :parent_name="row.item.parent"/>
                      </div>
                      <router-link :to="`/user/details/${row.item.id}`" class="font-weight-bold"> {{
                          row.item.name
                        }}
                      </router-link>
                      <div>
                        <b-badge> {{ row.item.id }}</b-badge>
                      </div>
                    </template>

                    <template #cell(username)="row">
                      <span> {{ row.item.username }}</span>
                    </template>

                    <template #cell(actions)="row">
                      <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                                  class="m-2">

                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>

                        <b-dropdown-item href="#" @click="userDetails(row.item.id)"><span class="pr-4"><i
                            class="fas fa-eye"></i></span> View details
                        </b-dropdown-item>


                        <b-dropdown-item href="#" @click="callHistory(row.item)" v-b-modal.modal-call>
                          <span class="pr-4"><i class="fas fa-phone-volume"></i></span> Call log
                        </b-dropdown-item>

                      </b-dropdown>
                    </template>

                  </b-table>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-12">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="total_user"
                      :per-page="perPage"
                      @change="handlePageChange"
                      size="lg"
                      class="my-0"
                  ></b-pagination>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <!--    Call history -->
    <b-modal id="modal-call" size="xl" :title="`Add call log ( ${user.user_name} )`" hide-footer>

      <b-form @submit.prevent="saveCallHisory" @reset="onReset" v-if="show">

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Response *</label>
              <select class="form-control" v-model="call_history.call_response">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="call_type">Call Type *</label>
              <select name="" id="call_type" class="form-control" v-model="call_history.call_type">
                <option value="inbound">Inbound</option>
                <option value="outbound">Outbound</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Status *</label>
              <v-select
                  :options="call_category"
                  :reduce="call_category => call_category.id"
                  v-model="call_history.call_category"
                  class="h-100"
                  label="title"
                  name="title">
              </v-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Remarks: *</label>
              <b-form-textarea
                  id="textarea"
                  v-model="call_history.remarks"
                  placeholder="Remarks:*"
                  rows="1"
              ></b-form-textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <b-form-checkbox
                id="checkbox-1"
                v-model="call_history.is_reminder"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
            >
              Want a reminder
            </b-form-checkbox>

            <div v-if="call_history.is_reminder ==1">
              <b-form-datepicker id="example-datepicker" v-model="call_history.reminder_date"
                                 class="mb-2"></b-form-datepicker>
            </div>

          </div>
        </div>

        <div class="row mt-10">
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <h4>Call history</h4>
            <b-table
                responsive
                striped hover
                :items="callHistories"
                :fields="call_history_filed"
                thead-class="bg-primary text-white">
            </b-table>
          </div>
        </div>

      </b-form>
    </b-modal>

    <b-modal
        id="patientModal"
        title="Add new patient"
        size="lg" ok-only
        @hidden="resetModalNewPatent"
        @ok="handlePatient">
      <form ref="form" @submit.stop.prevent="saveNewPatient">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <b-form-group
                  class="mb-0">
                <label for="">First name <span class="text-danger">*</span></label>
                <b-form-input
                    id="input-formatter"
                    v-model="patient_register.first_name"
                    :state="patient_first_name_state"
                    placeholder="Enter first name"
                    required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <b-form-group
                  class="mb-0">
                <label for="">Last name <span class="text-danger">*</span></label>
                <b-form-input
                    id="input-formatter"
                    v-model="patient_register.last_name"
                    placeholder="Enter last name"
                    :state="patient_last_name_state"
                    required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <b-form-group
                  class="mb-0">
                <label for="">Age <span class="text-danger">*</span></label>
                <b-form-input
                    id="input-formatter"
                    type="date"
                    v-model="patient_register.age"
                    placeholder="Enter age"
                    :state="patient_age_state"
                    required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-6">

            <b-form-group v-slot="{ ariaDescribedby }">
              <label for="">Select gender <span class="text-danger">*</span></label>
              <b-form-radio-group
                  id="radio-group-1"
                  v-model="patient_register.gender"
                  :options="gender"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
                  required
                  :state="patient_gender_state"
              ></b-form-radio-group>
            </b-form-group>

          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Phone number <span class="text-danger">*</span></label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon1">+88</span>

                <b-form-input
                    id="input-formatter"
                    v-model="patient_register.phone_number"
                    placeholder="Enter phone number"
                    :state="validation"
                    type="number"
                    required
                ></b-form-input>

                <b-form-invalid-feedback :state="validation">
                  Your phone number must be 11 characters long.
                </b-form-invalid-feedback>

                <b-form-valid-feedback :state="validation">
                  Looks Good.
                </b-form-valid-feedback>

              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <vue-snotify></vue-snotify>

    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import {PatientListMixin} from "../../../mixins/PatientListMixin";

export default {
  name: "List",
  mixins: [PatientListMixin],
  title: 'Patient list',
}
</script>

<style scoped>

</style>
