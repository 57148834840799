import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const PatientListMixin = {
    data() {
        let route_name = this.$route.name;
        return {
            downloadLoading: false,
            filename: '',
            autoWidth: true,
            bookType: 'xlsx',
            breadcrumbs: route_name + 'users',
            districts: [],
            thanas: [],
            json_fields: {
                "name": {
                    field: "name",
                },
                "slug": {
                    field: "slug",
                },
                "Email": {
                    field: "email",
                },
                "Phone number": {
                    field: "phone_number",
                },
                "Registration date": {
                    field: "registration_date",
                },
                "Is confirmed ? ": {
                    field: 'confirmation',
                    callback: (value) => {
                        return value == true ? 'valid' : 'invalid';
                    },
                },

            },
            loading: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            localActive: false,
            nameState: null,
            emailState: null,
            users: [],
            items: [],
            fields: [
                {
                    key: 'image',
                    label: 'Image',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'username',
                    label: 'Username',
                    sortable: true
                },
                {
                    key: 'slug',
                    label: 'Slug',
                    sortable: true
                },
                {
                    key: 'phone_number',
                    label: 'Phone number',
                    sortable: true
                },
                {
                    key: 'confirmation',
                    label: 'Is confirmed?',
                    sortable: true,
                },
                {
                    key: 'otp',
                    label: 'Is otp verified?',
                    sortable: true,
                },
                {
                    key: 'registration_date',
                    label: 'Reg date',
                    sortable: true,
                },
                {
                    key: 'last_update_date',
                    label: 'Updated date',
                    sortable: true,
                },
                {
                    key: "actions"
                }
            ],
            filter: null,
            filterOn: [],
            filters: {
                id: '',
                name: '',
                username: '',
                phone_number: '',
                confirmation: '',
                otp: '',
                registration_date: '',
                slug: '',
            },
            startDate: null,
            endDate: null,
            show: true,
            form: {
                comment: '',
                module_type: '',
                password: '',
            },
            base_url: process.env.VUE_APP_API_URL,
            user_id: '',
            callHistories: [],
            call_history_filed: [
                {
                    label: 'Sl',
                    key: 'index',
                    sortable: true,
                },
                {
                    label: 'Orko user',
                    key: 'fullname',
                    sortable: true,
                },
                {
                    label: 'User type',
                    key: 'user_type',
                    sortable: true,
                },
                {
                    label: 'Call category',
                    key: 'call_category',
                    sortable: true,
                },
                {
                    label: 'Call Type',
                    key: 'call_type',
                    sortable: true,
                },
                {
                    label: 'Call Status',
                    key: 'call_status',
                    sortable: true,
                },
                {
                    label: 'Call Response',
                    key: 'call_response',
                    sortable: true,
                },
                {
                    label: 'Reminder date',
                    key: 'reminder_date',
                    sortable: true,
                },
                {
                    label: 'Call action date',
                    key: 'call_action_date',
                    sortable: true
                },
                {
                    label: 'Remarks',
                    key: 'remarks',
                    sortable: true
                },
                {
                    label: 'Created at',
                    key: 'created_at',
                    sortable: true
                }

            ],
            call_history: {
                call_response: '',
                call_type: '',
                remarks: '',
                call_category: '',
                reminder_date: '',
                is_reminder: '',
            },
            call_category: [],
            user: {
                user_name: '',
                user_id: ''
            },
            points: [],
            total_point: '',
            point_fields: [
                {
                    label: 'Point type',
                    key: 'point_type_description',
                },
                {
                    label: 'No of occurrence',
                    key: 'no_of_occurrence',
                },
                {
                    label: 'Point earned',
                    key: 'point_earned',
                },

            ],
            user_name: '',
            user_phone_number: '',
            user_image: null,
            doctor_id: '',
            call_log: {},
            agent_type: 'agent_pharmacy',
            partent_sub_types: [],
            parient_id: '',
            total_user: '',
            patient_register: {
                first_name: '',
                last_name: '',
                phone_number: '',
                age: '',
                gender: 'male',
            },
            patient_first_name_state: null,
            patient_last_name_state: null,
            patient_age_state: null,
            patient_gender_state: null,
            gender: [
                {text: 'Male', value: 'male'},
                {text: 'Female', value: 'female'},
                {text: 'Other', value: 'other'},
            ],
            start_date: null,
            end_date: null,

        }
    },
    // metaInfo: {
    //     title: 'Patient list',
    //     htmlAttrs: {
    //         lang: 'en-US'
    //     }
    // },
    created() {
        localStorage.setItem('tabIndex', 1)
        this.$root.$emit('call-active-menu', 1);
        this.getUser();
        this.userPhoto();
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Patient list", route: "patient-users"},
        ]);
    },

    methods: {
        getUser() {
            this.loading = true
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list?api_token=${localStorage.getItem('api_token')}&user_type=patient&offset=0&limit=${this.perPage}&platform=backoffie${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.items = response.data.data;
                        this.total_user = response.data.total_count
                        this.loading = false;
                    }
                })
        },
        handlePageChange(value) {
            this.loading = true;
            var offset = value - 1;
            var correctValue = offset * this.perPage;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list?api_token=${localStorage.getItem('api_token')}&user_type=patient&offset=${correctValue}&limit=${this.perPage}&platform=backoffice${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        searchDoctor(event) {
            event.preventDefault()
            this.loading = true
            let form = new FormData();
            form.append('user_type', 'patient');
            form.append('limit', '100');
            form.append('offset', '0');
            form.append('search_text', this.filter);
            axios.post(`${process.env.VUE_APP_URL}/api/v1/user/filter/list?api_token=${localStorage.getItem('api_token')}`, form)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.loading = false;
                        this.items = response.data.data;
                        this.total_user = response.data.total_count;
                    }
                })
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        userDetails(id) {
            this.$router.push(`/user/details/${id}`);
        },

        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },

        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        },

        sendInfo(item) {
            this.user_id = item.id
        },
        callHistory(item) {
            this.call_log = item;
            this.user.user_name = item.name;
            this.user.user_id = item.id;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs?user_id=${item.id}&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.callHistories = response.data.data;
                });
            axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/category/list?call_type=outbound&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.call_category = response.data.data
                });
        },
        saveCallHisory() {

            let form = new FormData();
            form.append('user_id', this.user.user_id);
            form.append('call_category_id', this.call_history.call_category);
            form.append('call_action_status_id', 1);
            form.append('call_type', this.call_history.call_type);
            form.append('call_response', this.call_history.call_response);
            if (this.call_history.is_reminder == 1) {
                form.append('has_reminder', this.call_history.is_reminder);
                form.append('reminder_date', this.call_history.reminder_date);
            }
            form.append('remarks', this.call_history.remarks);
            form.append('agent_username', '');

            axios.post(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs/store?api_token=${localStorage.getItem('api_token')}`, form)
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error(response.data.message);
                    } else {
                        // this.$bvModal.hide('modal-call')
                        this.call_history = [];
                        this.callHistory(this.call_log);
                        this.$snotify.success('Call log added Successfully.');
                    }

                })
        },

        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.form.email = ''
            this.form.name = ''
            this.form.food = null
            this.form.checked = []
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.form.password = '';
            this.form.comment = '';
            this.call_history = {};
            this.call_log = {},
                this.$nextTick(() => {
                    this.show = true
                });


        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return '../../../public/media/users/blank.png'
            }
        },

        dateRangeClear() {
            this.startDate = '';
            this.endDate = ''
            this.start_date = null
            this.end_date = null
        },
        showImage(img) {
            const h = this.$createElement
            // More complex structure
            const messageVNode = h('div', {class: ['foobar']}, [
                h('b-img', {
                    props: {
                        src: `${process.env.VUE_APP_API_URL}/${img}`,
                        thumbnail: true,
                        center: false,
                    }
                })
            ])
            // We must pass the generated VNodes as arrays
            this.$bvModal.msgBoxOk([messageVNode], {
                buttonSize: 'sm',
                centered: true, size: 'lg'
            })
        },

        resetModal() {
            this.doctor_id = '';
            this.parient_id = '';
        },

        importPatient() {
            this.$bvModal.show('importModal')
        },
        addNewPatient() {
            this.$bvModal.show('patientModal')
        },
        handleImport(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmitImport()
        },
        handleSubmitImport() {
            let form = new FormData();
            // let imported_file = document.getElementById('imported_file').files[0];
            let imported_file = this.$refs.imported_file.files[0];
            form.append("imported_file", imported_file);
            axios
                .post(`${process.env.VUE_APP_URL}/api/v1/patient/import?api_token=${localStorage.getItem('api_token')}`, form)
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error(response.data.message);
                    }
                    this.$emit('call_basic_info')
                    this.resetModal();
                    this.$bvModal.hide('importModal')
                    this.$snotify.success('Patient successfully imported');
                })
        },

        handlePatient(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.saveNewPatient()
        },
        checkValidationForAddPatient() {
            const valid = this.$refs.form.checkValidity()
            this.patient_first_name_state = valid
            this.patient_last_name_state = valid
            this.patient_age_state = valid
            this.patient_gender_state = valid

            return valid
        },
        saveNewPatient() {
            if (!this.checkValidationForAddPatient()) {
                return
            }
            if (!this.validation) {
                return "Phone number 11 digit required";
            }
            let form = new FormData();
            form.append("first_name", this.patient_register.first_name);
            form.append("last_name", this.patient_register.last_name);
            form.append("dob", this.patient_register.age);
            form.append("gender", this.patient_register.gender);
            // form.append("email", "");
            form.append("phone_number", "+88" + this.patient_register.phone_number);
            form.append("user_type", 'patient');
            form.append("password", this.patient_register.phone_number);
            form.append("platform", "register");
            form.append("nature_of_service", "0");
            axios
                .post(`${process.env.VUE_APP_URL}/api/v1/patient/create`, form)
                .then(response => {
                    if (response.data.status_code == '200') {
                        this.getUser();
                        this.$bvModal.hide('patientModal')
                        return this.$snotify.success(response.data.message);
                    }


                });
        },
        resetModalNewPatent() {
            this.patient_register = {
                first_name: '',
                last_name: '',
                phone_number: '',
                age: '',
                gender: 'male',
            };
            this.patient_first_name_state = null;
            this.patient_last_name_state = null;
            this.patient_age_state = null;
            this.patient_gender_state = null;
            this.patient_phone_number_state = null;
        },

    },

    computed: {
        rows() {
            return this.users.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.registration_date)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }

                if (this.trendy) {
                    return item.is_trending == 1;
                }

                if (this.qc) {

                    return item.available_qc == 1
                }

                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })


            return filtered.length > 0 ? filtered : [{
                id: '',
                name: '',
                phone_number: '',
                confirmation: '',
                otp: '',
                registration_date: '',
                slug: '',
            }]
        },

        filteredData() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.registration_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        routeName() {
            return this.$route.name
        },
        validation() {
            return this.patient_register.phone_number.length == 11
        }
    },

    currentPage: {
        handler: function (value) {
            this.getUser().catch(error => {
                console.error(error)
                console.error(value)
            })
        }
    },
}
